body {
  margin: 0;
  font-family: 'Poppins', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.submenu-item.active {
  font-weight: 500;
  color: #d5b343;
  border-left: 2px solid #d5b343 !important;
}

.menu-item.active {
  font-weight: 500;
  color: #0073ea;
}

a.active {
  color: #0073ea !important;
}

button:disabled,
button:disabled:hover,
button[disabled]{
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}